.result {
  position: relative;

  margin: 1.5rem 0;
  height: 8rem;
  max-height: 8rem;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.result.size-1 {
  font-size: 2.75rem;
  line-height: 1.4;

  height: auto;
  max-height: none;
  min-height: 8rem;

  align-items: start;
}
.result.size-2 {
  font-size: 3.25rem;
  line-height: 1.2;
  padding-bottom: 0.5rem;
}
.result.size-3 {
  font-size: 4rem;
  line-height: 1.2;
  padding-bottom: 0.75rem;
}
.result.size-4 {
  font-size: 5rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}
.result.size-5 {
  font-size: 7rem;
  line-height: 1;
  padding-bottom: 1rem;
}

.result p {
  display: inline-block;
  text-align: left;
  margin: 0 0.75em;
  line-height: inherit;
}

.result p::before,
.result p::after {
  position: absolute;
  margin-top: -0.5rem;

  width: 1em;
  margin-left: -0.5em;

  font-size: 1.2em;
  color: #49e;
}

.result p::before {
  content: '“';
  text-align: left;
}

.result p::after {
  content: '”';
  text-align: right;
}

.result {
  transition: opacity 0.5s;
}

.result.loading {
  opacity: 0.25;
}
